import React, {useEffect, useState} from 'react';
import {
    Grid,
    Divider,
    Button,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from "@mui/material";
import {Edit, Archive, FileCopy, MoreHoriz, Person, Logout, HelpCenter, ContactPhone, ChromeReaderMode} from '@mui/icons-material';
import DropdownMenu from "./DropdownMenu";
import { useHistory, useLocation } from 'react-router-dom';
import {$crud} from "../factories/CrudFactory";

export default function Header(props) {
    const history = useHistory();
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem("user") as string);

    const {maps = [], selectedMapId, setSelectedMapId} = props;

    if(maps.length && !selectedMapId)
        setSelectedMapId(maps[0]._id);

    const [pathName, setPathName] = useState("");

    useEffect(() => {
        setPathName(location.pathname.replace(/\//g, ''));
    }, [location.pathname]);

    const logout = async () => {
        try{
            const {type} = await $crud.post("logout");
            if(type === "success"){
                await localStorage.clear();
                history.push("/");
            }
        } catch (e) {
            console.log("Error", e)
        }
    };

    return <>
        <Grid container alignItems={"center"} justifyContent={"space-between"} className={"header-wrapper px-3 py-2"} spacing={2}>
            <Grid item>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item>
                        <a href={"/"}>
                            <Grid container alignItems={"end"} className={"logo"}>
                                <Grid item>
                                    <img src={"../../images/logo.png"} className={"img img-fluid"} />
                                </Grid>
                                <Grid item>
                                    <div className={"d-flex flex-column"}>
                                        <Typography variant={"body1"} className={"title pr-3"}><span>Geofence</span> <span>Mapper</span></Typography>
                                        <Typography variant={"caption"} className={"title-slogan"}>Professional Tools for Virtual Geofencing</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </a>
                    </Grid>

                    {
                        !["Builder"].includes(user.role) && <>
                            <Grid item>
                                <Button
                                    size={"small"}
                                    variant={["maps", "add-update-map"].includes(pathName) ? "outlined" : "text"}
                                    onClick={() => history.push("/maps")}
                                >
                                    Maps
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    size={"small"}
                                    variant={["locations"].includes(pathName) ? "outlined" : "text"}
                                    onClick={() => history.push("/locations")}
                                >
                                    Locations
                                </Button>
                            </Grid>
                        </>
                    }

                    {
                        ["Builder"].includes(user.role) && <>
                            <Grid item>
                                <Button
                                    size={"small"}
                                    variant={["builder-maps"].includes(pathName) ? "outlined" : "text"}
                                    onClick={() => history.push("/builder-maps")}
                                >
                                    Site Map
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    size={"small"}
                                    variant={["builder-house-plots"].includes(pathName) ? "outlined" : "text"}
                                    onClick={() => history.push("/builder-house-plots")}
                                >
                                    House Plots
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    size={"small"}
                                    variant={["builder-enquires"].includes(pathName) ? "outlined" : "text"}
                                    onClick={() => history.push("/builder-enquires")}
                                >
                                    Enquires
                                </Button>
                            </Grid>
                        </>
                    }

                    {
                        Boolean(Number(localStorage.getItem("admin"))) && <Grid item>
                            <Button
                                size={"small"}
                                variant={["users", "add-update-user"].includes(pathName) ? "outlined" : "text"}
                                onClick={() => history.push("/users")}
                            >
                                Users
                            </Button>
                        </Grid>
                    }

                    {
                        Boolean(Number(localStorage.getItem("admin"))) && <Grid item>
                            <Button
                                size={"small"}
                                variant={["purchase-parking"].includes(pathName) ? "outlined" : "text"}
                                onClick={() => history.push("/purchase-parking")}
                            >
                                Parking Direction
                            </Button>
                        </Grid>
                    }

                    {
                        Boolean(Number(localStorage.getItem("admin"))) && <Grid item>
                            <Button
                                size={"small"}
                                variant={["bookings"].includes(pathName) ? "outlined" : "text"}
                                onClick={() => history.push("/bookings")}
                            >
                                Bookings
                            </Button>
                        </Grid>
                    }

                </Grid>
            </Grid>

            {
                ["builder-maps"].includes(pathName) && <Grid item xs={2}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-select-small-label">Select Map</InputLabel>
                        <Select
                            label="Select State"
                            value={selectedMapId ?? ""}
                            onChange={(e) => setSelectedMapId(e.target.value)}
                            required
                        >
                            {
                                maps.map(map => {
                                    return <MenuItem value={map._id}>{map.area}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
            }

            <Grid item>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item>
                        <DropdownMenu
                            title={"Tools"}
                            titleIcon={undefined}
                            disabled={true}
                            options={[
                                {
                                    name: "Edit",
                                    icon: <Edit />
                                },
                                {
                                    name: "Duplicate",
                                    icon: <FileCopy />,
                                    divider: <Divider sx={{ my: 5 }} />
                                },
                                {
                                    name: "Archive",
                                    icon: <Archive />
                                },
                                {
                                    name: "More",
                                    icon: <MoreHoriz />
                                }
                            ]}
                        />
                    </Grid>

                    <Grid item>
                        <DropdownMenu
                            title={"Help"}
                            titleIcon={undefined}
                            options={[
                                {
                                    name: "TRAINING",
                                    icon: <ChromeReaderMode />,
                                    disabled: true
                                },
                                {
                                    name: "HOW TO GUIDE",
                                    icon: <HelpCenter />,
                                    disabled: true
                                },
                                {
                                    name: "REACH OUT TO US",
                                    icon: <ContactPhone />,
                                    disabled: true
                                }
                            ]}
                        />
                    </Grid>

                    <Grid item>
                        <DropdownMenu
                            title={"What's New"}
                            titleIcon={undefined}
                            disabled={true}
                            options={[
                                {
                                    name: "Edit",
                                    icon: <Edit />
                                },
                                {
                                    name: "Duplicate",
                                    icon: <FileCopy />,
                                    divider: <Divider sx={{ my: 5 }} />
                                },
                                {
                                    name: "Archive",
                                    icon: <Archive />
                                },
                                {
                                    name: "More",
                                    icon: <MoreHoriz />
                                }
                            ]}
                        />
                    </Grid>

                    <Grid item>
                        <DropdownMenu
                            title={user?.firstName + " " + user?.lastName}
                            titleIcon={<Person className={"mr-1"} fontSize={"medium"}/>}
                            options={[
                                {
                                    name: "Sign Out",
                                    icon: <Logout  />,
                                    onClick: logout
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
}

