import React, {useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {$crud} from "../../factories/CrudFactory";
import {
    Button,
    Dialog,
    Grid,
    IconButton,
    Slide,
    TextField,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import Swal from "sweetalert2";
import {X} from 'react-feather';
import {makeStyles} from "tss-react/mui";
import ReactQuill from "react-quill";

const useStyles = makeStyles()(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        flexGrow: 1,
    },
    title: { flexGrow: 1 },
    listContainer: {
        maxHeight: 200,
        overflowY: "auto"
    },
    sortablePlaceholder: {
        flex: 1,
        width: 50,
        maxWidth: 50,
        background: "#eee"
    }
}));

function EditPlotDialog({ open, onClose, _id, getData, onSuccess }) {
    const [params, setParams] = useState<any>({});
    const history = useHistory();
    const location = useLocation();
    const { classes } = useStyles();

    useEffect(() => {
        setParams({});
        if (_id)
            getLocationById();
    }, [_id]);

    const getLocationById = async () => {
        try {
            const { data } = await $crud.get(`builder/location/${_id}`);
            setParams({
                ...data.location,
                _id: undefined
            });
        } catch (e) {
            console.log(e);
        }
    };

    const save = async () => {
        const warnings: string[] = [];
        if (params.Name?.trim() === "")
            warnings.push("Please enter Name.");

        if (warnings.length) {
            await Swal.fire({
                icon: "warning",
                text: warnings[0]
            });
            return;
        }

        try{
            const {type} = await $crud.post(`builder/location/update/${_id}`, params);
            if(type === "success")
                onSuccess();

            onClose();
            getData();
        } catch (e) {
            console.log(e);
        }
    }

    const modules = {
        toolbar: [
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            ["link"],
            [{ color: [] }, { background: [] }],
            [{ direction: "rtl" }],
        ],
    };

    return (
        <div style={{ display: 'flex' }}>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={open}
                TransitionComponent={Slide}
                onClose={onClose}
            >
                <Grid container spacing={2} className={"px-3 pt-2 pb-3"}>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item>
                                <Typography variant="h6" className={classes.title}>
                                    Update Plot
                                </Typography>
                            </Grid>

                            <Grid item>
                                <IconButton
                                    onClick={onClose}
                                >
                                    <X />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={7}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={"Name"}
                            type='text'
                            size="small"
                            value={params.Name || ""}
                            onChange={(e) => setParams({...params, Name: e.target.value})}
                            required
                        />
                    </Grid>

                    <Grid item xs={5}>
                        <FormControl
                            fullWidth
                            size="small"
                        >
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={params.builderLocationType || ""}
                                label="Status"
                                onChange={(e) => {setParams({...params, builderLocationType: e.target.value})}}
                                className={"input-bar"}
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                <MenuItem value={1}>Quick Move-In Home</MenuItem>
                                <MenuItem value={2}>Closed</MenuItem>
                                <MenuItem value={3}>In Contract</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={"Area"}
                            type='text'
                            size="small"
                            value={params.plotDetails?.area || ""}
                            onChange={(e) => setParams({...params, plotDetails: {...params.plotDetails, area: e.target.value}})}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={"Number"}
                            type='text'
                            size="small"
                            value={params.plotDetails?.number || ""}
                            onChange={(e) => setParams({...params, plotDetails: {...params.plotDetails, number: e.target.value}})}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={"Address"}
                            type='text'
                            size="small"
                            value={params.plotDetails?.address || ""}
                            onChange={(e) => setParams({...params, plotDetails: {...params.plotDetails, address: e.target.value}})}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={"Facing"}
                            type='text'
                            size="small"
                            value={params.plotDetails?.facing || ""}
                            onChange={(e) => setParams({...params, plotDetails: {...params.plotDetails, facing: e.target.value}})}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography>Description</Typography>
                        <ReactQuill
                            value={params.plotDetails?.description || ""}
                            onChange={(content) => {setParams({...params, plotDetails: {...params.plotDetails, description: content}})}}
                            modules={modules}
                            style={{height: "150px", marginBottom: "100px"}}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justifyContent={"flex-end"}>
                            <Grid item className={"p-0"}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="btn btn-success mr-3"
                                    style={{ backgroundImage: 'linear-gradient(to right, #E160D9, #6841EC)' }}
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>

                            <Grid item className={"p-0"}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="btn btn-success"
                                    style={{ backgroundImage: 'linear-gradient(to right, #E160D9, #6841EC)' }}
                                    onClick={save}
                                >
                                    Update Plot
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );

}

export default EditPlotDialog;
