import React, {useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Button,
    Dialog,
    Grid,
    IconButton,
    Slide,
    Typography,
} from '@mui/material';
import {X} from 'react-feather';
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        flexGrow: 1,
    },
    title: { flexGrow: 1 },
    listContainer: {
        maxHeight: 200,
        overflowY: "auto"
    },
    sortablePlaceholder: {
        flex: 1,
        width: 50,
        maxWidth: 50,
        background: "#eee"
    }
}));

function PlotDetailsDialog({ open, onClose, locationData }) {
    const [params, setParams] = useState<any>({});
    const history = useHistory();
    const location = useLocation();
    const { classes } = useStyles();

    return (
        <div style={{ display: 'flex' }}>
            <Dialog
                maxWidth="md"
                fullWidth
                open={open}
                TransitionComponent={Slide}
                onClose={onClose}
            >
                <Grid container spacing={2} className={"px-3 pt-2 pb-3"}>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item>
                                <Typography variant="h6" className={classes.title}>
                                    Plot Details
                                </Typography>
                            </Grid>

                            <Grid item>
                                <IconButton
                                    onClick={onClose}
                                >
                                    <X />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant={"subtitle1"}><b>Area: </b>{locationData.plotDetails?.area || ""}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant={"subtitle1"}><b>Number: </b>{locationData.plotDetails?.number || ""}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant={"subtitle1"}><b>Address: </b>{locationData.plotDetails?.address || ""}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant={"subtitle1"}><b>Facing: </b>{locationData.plotDetails?.facing || ""}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <div
                            dangerouslySetInnerHTML={{ __html: locationData.plotDetails?.description }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent={"flex-end"} className={"mt-3"}>
                            <Grid item className={"p-0"}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="btn btn-success"
                                    style={{ backgroundImage: 'linear-gradient(to right, #E160D9, #6841EC)' }}
                                    onClick={onClose}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );

}

export default PlotDetailsDialog;
