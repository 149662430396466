import React, {useEffect, useMemo, useRef, useState} from "react";
import {Divider, Grid, Paper, Slider, Typography, useTheme, Button, TextField, IconButton, FormGroup, FormControlLabel, Switch, Select, MenuItem, FormControl, InputLabel} from "@mui/material";
import {X} from 'react-feather';

export default function EditLocationPopup ({open, locationId, locations, setLocations, onClose, update, deleteLocation, reverse}) {
    const [params, setParams] = useState<any>({});
    const [updateGroup, setUpdateGroup] = useState<any>(false);
    const [fifthPoint, setFifthPoint] = useState<any>(false);
    const location = locations.find(s => s.id === locationId);

    useEffect(() => {
        if(!location?.groupUniqueKey)
            setUpdateGroup(false);
        if(locationId && locations.length)
            setParams({
                ...params,
                name: location?.Name,
                style: location?.style,
                fifthPoint: location?.fifthPoint
            });
    }, [locationId, locations]);

    return <div className={`popup-wrapper ${open && "show"}`}>
        <div className={"content"}>
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container alignItems={"center"} justifyContent={"space-between"}>
                            <Grid>
                                <Typography variant={"h6"}>Edit</Typography>
                            </Grid>

                            <Grid>
                                <IconButton
                                    onClick={onClose}
                                >
                                    <X />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        location?.groupUniqueKey && <Grid item xs={12}>
                            <Grid container justifyContent={"center"}>
                                <Grid item>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    onChange={e => setUpdateGroup(e.target.checked)}
                                                />
                                            }
                                            label="Update Group"
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {
                        !updateGroup && <>
                            <Grid item xs={12}>
                                <Typography variant={"subtitle1"} className={"mb-2"}>Update Name</Typography>
                                <TextField
                                    fullWidth
                                    autoComplete={"off"}
                                    size="small"
                                    value={params.name || ""}
                                    variant="outlined"
                                    placeholder="Name"
                                    label="Name"
                                    onChange={e => {
                                        setLocations(prev => {
                                            return prev.map(s => s.id === locationId ? {
                                                ...s,
                                                Name: e.target.value
                                            } : s)
                                        });
                                        setParams({
                                            ...params,
                                            name: e.target.value
                                        });
                                    }
                                    }
                                    className={"input-bar"}
                                />
                            </Grid>

                            <Grid item xs={12} className={"mt-3"}>
                                <Typography variant={"subtitle1"} className={"mb-2"}>Font Size</Typography>
                                <div className={"pl-2"}>
                                    <Slider
                                        min={1}
                                        max={70}
                                        value={params?.style?.fontSize || 10}
                                        onChange={(e, value) => setLocations(prev => {
                                            return  prev.map(s => s.id === locationId ? {
                                                ...s,
                                                style: {
                                                    fontSize: value
                                                }
                                            }:s )
                                        })}
                                    />
                                </div>
                            </Grid>
                        </>
                    }


                    {
                        updateGroup && location?.groupUniqueKey && <>
                            <Grid item xs={12}>
                                <Typography variant={"subtitle1"} className={"mb-2"}>Update Group Name</Typography>
                                <TextField
                                    fullWidth
                                    autoComplete={"off"}
                                    size="small"
                                    value={params.name?.replace(/\d+\s*$/, "") || ""}
                                    variant="outlined"
                                    placeholder="Name"
                                    label="Name"
                                    onChange={e => {
                                        setLocations(prev => {
                                            return prev.map(s => s.groupUniqueKey === location.groupUniqueKey ? {
                                                ...s,
                                                Name: e.target.value + (s.Name.match(/\d+\s*$/)?.[0] || s.Name)
                                            } : s)
                                        });
                                        setParams({
                                            ...params,
                                            name: e.target.value
                                        });
                                    }
                                    }
                                    className={"input-bar"}
                                />
                            </Grid>

                            <Grid item xs={12} className={"mt-3"}>
                                <Typography variant={"subtitle1"} className={"mb-2"}>Group Font Size</Typography>
                                <div className={"pl-2"}>
                                    <Slider
                                        min={1}
                                        max={70}
                                        value={params.groupFontSize || 0}
                                        onChange={(e, value) => {
                                            setLocations(prev => {
                                                return  prev.map(s => s.groupUniqueKey === location.groupUniqueKey ? {
                                                    ...s,
                                                    style: {
                                                        fontSize: value
                                                    }
                                                }:s )
                                            });
                                            setParams({
                                                ...params,
                                                groupFontSize: value
                                            });
                                        }}
                                    />
                                </div>
                            </Grid>
                        </>
                    }
                </Grid>
            </div>

            <Grid container alignItems={"center"}>
                <Grid item xs={12} className={"mt-3"}>
                    <FormGroup>
                        <FormControlLabel
                            className={"m-0"}
                            control={
                                updateGroup ? <Switch
                                    checked={fifthPoint}
                                    onChange={(e) => {
                                        const checked = e.target.checked;
                                        setFifthPoint(checked);
                                        setLocations(prev => prev.map(s => s.groupUniqueKey === location.groupUniqueKey
                                                ? { ...s, fifthPoint: checked }
                                                : s
                                        ));
                                    }}
                                /> : <Switch
                                    checked={params.fifthPoint}
                                    onChange={(e) => {
                                        const checked = e.target.checked;
                                        setLocations(prev => prev.map(s => s.id === locationId
                                                ? { ...s, fifthPoint: checked }
                                                : s
                                        ));
                                    }}
                                />
                            }
                            label="Do you want 5th point as a center point ?"
                            labelPlacement={"start"}
                        />
                    </FormGroup>
                </Grid>

                {
                    (updateGroup ? fifthPoint : params.fifthPoint) && <Grid item xs={9} className={"mt-3"}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-select-small-label">Select 5th point</InputLabel>
                            <Select
                                fullWidth
                                label="Select Center"
                                value={params.center ?? "center"}
                                onChange={(e) => setParams({ ...params, center: e.target.value })}
                                required
                            >
                                <MenuItem value=''>Select Center</MenuItem>
                                <MenuItem value={"center"}>{"Center"}</MenuItem>
                                <MenuItem disabled={true} value={"top"}>{"Top"}</MenuItem>
                                <MenuItem disabled={true} value={"bottom"}>{"Bottom"}</MenuItem>
                                <MenuItem disabled={true} value={"left"}>{"Left"}</MenuItem>
                                <MenuItem disabled={true} value={"right"}>{"Right"}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                }

                {
                    updateGroup && location?.groupUniqueKey && <Grid item className={"mt-3"}>
                        <Button
                            size={"small"}
                            // disabled={loading}
                            className="button mr-2"
                            onClick={() => reverse()}
                        >
                            Reverse Labelling
                        </Button>
                    </Grid>
                }
            </Grid>

            <div className={"buttons"}>
                <Grid container justifyContent={"end"} alignItems={"center"}>
                    <Grid item>
                        <Button
                            size={"small"}
                            // disabled={loading}
                            variant={"outlined"}
                            className="mr-2 py-1"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            size={"small"}
                            // disabled={loading}
                            className="button mr-2"
                            onClick={() => deleteLocation({deleteGroup: updateGroup})}
                        >
                            {updateGroup ? "Delete Group" : "Delete"}
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            size={"small"}
                            // disabled={loading}
                            className="button"
                            onClick={update}
                        >
                            {updateGroup ? "Update Group" : "Update"}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>

    </div>;
}