import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableRow,
    Paper,
    TableHead,
    Tooltip,
    Pagination,
    Grid,
    Typography,
    TextField,
    Button
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import {debounce} from "lodash";
import {$crud} from "./factories/CrudFactory";
import AddUserDialog from "./AddUserDialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        alignContent: "center"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    },
    '& td, & th': {
        padding: "0px 15px"
    },
}));

const Users = () => {
    const [users, setUsers] = useState<any[]>([]);

    const rowsPerPage = [25, 50, 75, 100];
    const [pagination, setPagination] = useState({
        page: 1,
        limit: rowsPerPage[0]
    });
    const [total, setTotal] = useState({
        pages: 0,
        records: 0
    });

    const [filters, setFilters] = useState({search: ""});
    const [search, setSearch] = useState<string>("");
    const [editUserId, setEditUserId] = useState(null);
    const [openUserDialog, setOpenUserDialog] = useState(false);
    let history = useHistory();

    const setFilter = useCallback((key, value) => {
        setFilters(prev => {
            return {
                ...prev,
                [key]: value
            };
        });
    }, []);

    const debounceSetFilter = useCallback(debounce(setFilter, 800), []);

    useEffect(() => {
        debounceSetFilter("search", search);
    }, [search]);

    const getUsers = async () => {
        try{
            const {data: {total, users}} = await $crud.get("users", {
                ...pagination,
                search
            });
            setUsers(users);
            setTotal({
                pages: Math.ceil(total / pagination.limit),
                records: total
            });
        }  catch (e) {
            console.log(e);
        }
    };

    const deleteUser = async (_id) => {
        try{
            const {type} = await $crud.delete(`users/${_id}`);
            if(type === "success")
                getUsers();
        }  catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getUsers();
    }, [pagination, filters]);

    const showAlert = (_id) => {
        Swal.fire({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this User?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                const confirmButtonText = Swal.getConfirmButton()!.textContent;
                deleteUser(_id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    };

    const closeAddUserDialog = async () => {
        setOpenUserDialog(false);
        setEditUserId(null);
    };

    return (
        <div style={{ display: 'flex'}}>
            <AddUserDialog open={openUserDialog} onClose={closeAddUserDialog} _id={editUserId} getData={getUsers}/>
            <div
                style={{
                    flex: 1,
                    margin: '0 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        // display: 'flex',
                        // justifyContent: 'space-between',
                        // alignItems: 'center',
                        width: '100%',
                        margin: '20px 0',
                    }}
                >
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <h5 style={{ marginRight: 'auto' }}>Admin User</h5>
                        </Grid>

                        <Grid item>
                            <Grid container alignItems={"center"}>
                                <Grid item>
                                    <Button
                                        type="submit"
                                        className="button mr-2"
                                        onClick={() => {
                                            setEditUserId(null);
                                            setOpenUserDialog(true);
                                        }}
                                    >
                                        Add New User
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        value={search}
                                        variant="outlined"
                                        placeholder="type for search...."
                                        label="Search By Name, Email"
                                        onChange={e => setSearch(e.target.value)}
                                        className={"input-bar"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                <TableContainer component={Paper} className={"table"}>
                    <Table sx={{ minWidth: 700 }} size={"small"} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '' }}>
                                    First Name
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '' }}>
                                    Last Name
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '' }}>
                                    Role
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '' }}>
                                    Email
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '8%' }}>
                                    Action
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                users.map((user, index) => (
                                    <StyledTableRow>
                                        <StyledTableCell>{user.firstName}</StyledTableCell>
                                        <StyledTableCell>{user.lastName}</StyledTableCell>
                                        <StyledTableCell>{user.role}</StyledTableCell>
                                        <StyledTableCell>{user.email}</StyledTableCell>
                                        <StyledTableCell>
                                            <Tooltip title="Edit">
                                                <IconButton onClick={() => {
                                                setEditUserId(user._id);
                                                setOpenUserDialog(true);
                                            }} aria-label="edit" style={{ padding: '6px', color: "orange" }}>
                                                    <EditIcon fontSize="medium" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => showAlert(user._id)} aria-label="delete" style={{ padding: '6px', color: "#f00" }}>
                                                    <DeleteIcon fontSize="medium" />
                                                </IconButton>
                                            </Tooltip>
                                        </StyledTableCell>
                                    </StyledTableRow >
                                ))
                            }
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <StyledTableCell style={{padding: "7px"}} colSpan={5}>
                                    <Grid container alignItems={"center"} justifyContent={"right"}>
                                        <Grid item>
                                            <Typography className={"font-weight-bold"} variant={"body1"}>Total : {total.records} | Page:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Pagination
                                                count={total.pages}
                                                color="primary"
                                                onChange={(e, value) => {
                                                    setPagination({...pagination, page: value})
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container justifyContent={"center"} alignItems={"center"}>
                                                <Grid item>
                                                    <Typography className={"font-weight-bold mr-2"} variant={"body2"}>Show</Typography>
                                                </Grid>

                                                <Grid item>
                                                    <select
                                                        style={{fontSize: "16px"}}
                                                        value={String(pagination.limit)} placeholder="" name="limit"
                                                        onChange={
                                                            e => {
                                                                setPagination({...pagination, limit: Number(e.target.value)})
                                                            }
                                                        }
                                                    >
                                                        {
                                                            rowsPerPage.map((val, key) => <option
                                                                key={key}
                                                                value={val}
                                                            >
                                                                {val}
                                                            </option>)
                                                        }
                                                    </select>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledTableCell>
                            </StyledTableRow >
                        </TableFooter>
                    </Table>
                </TableContainer>

            </div>
        </div>
    );
};

export default Users;
