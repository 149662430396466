import {LatLngLiteral} from "@googlemaps/google-maps-services-js";
import axios from "axios";
export const getBounds = (points: LatLngLiteral[]) => {
    const bounds = new google.maps.LatLngBounds();

    for (let n = 0; n < points.length; n++) {
        bounds.extend(points[n]);
    }

    return {bounds};
};

export function getDistance(lat1: number, lon1: number, lat2: number, lon2: number, unit: "M" | "K" | "N" = "M"): number {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    } else {
        const radlat1 = Math.PI * lat1 / 180;
        const radlat2 = Math.PI * lat2 / 180;
        const theta = lon1 - lon2;
        const radtheta = Math.PI * theta / 180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

        if (dist > 1) {
            dist = 1;
        }

        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;

        if (unit === "K") {
            dist = dist * 1.609344;
        }

        if (unit === "N") {
            dist = dist * 0.8684;
        }

        return dist;
    }
}

export const getCenterLatLng = (coordinates) => {
    const total = coordinates.length;
    const sum = coordinates.reduce((acc, coord) => {
        acc.lat += coord.lat;
        acc.lng += coord.lng;
        return acc;
    }, { lat: 0, lng: 0 });

    return {
        lat: sum.lat / total,
        lng: sum.lng / total
    };
};

export const getNavigateUrl = async (location) => {
    let destinationCoOrdinates;
    let url = "";

    if (location.shape === "circle") {
        destinationCoOrdinates = JSON.parse(location.circleCenter);
    } else if (location.shape === "marker") {
        destinationCoOrdinates = JSON.parse(location.bounds);
    } else {
        destinationCoOrdinates = await getCenterLatLng(JSON.parse(JSON.stringify([
            {
                lat: location.Latitude1,
                lng: location.Longitude1
            },
            {
                lat: location.Latitude2,
                lng: location.Longitude2
            },
            {
                lat: location.Latitude3,
                lng: location.Longitude3
            },
            {
                lat: location.Latitude4,
                lng: location.Longitude4
            }
        ])));
    }

    const destination = `${destinationCoOrdinates.lat},${destinationCoOrdinates.lng}`;

    try {
        const position = await new Promise<any>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        const origin = `${latitude},${longitude}`;
        url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
    } catch (error) {
        console.error('Error getting current location:', error);
    }

    return url;
};

export const getCenterByLocation = async (location) => {
    let destinationCoOrdinates;

    if (location.shape === "circle") {
        destinationCoOrdinates = JSON.parse(location.circleCenter);
    } else if (location.shape === "marker") {
        destinationCoOrdinates = JSON.parse(location.bounds);
    } else {
        destinationCoOrdinates = await getCenterLatLng(JSON.parse(JSON.stringify([
            {
                lat: location.Latitude1,
                lng: location.Longitude1
            },
            {
                lat: location.Latitude2,
                lng: location.Longitude2
            },
            {
                lat: location.Latitude3,
                lng: location.Longitude3
            },
            {
                lat: location.Latitude4,
                lng: location.Longitude4
            }
        ])));
    }

    return destinationCoOrdinates;
};

export const getLatitudeLongitude = async (address, apiKey) => {
    try {
        const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
            params: {
                address: address,
                key: apiKey
            }
        });
        const data = response.data;
        if (data.status === 'OK') {
            const result = data.results[0];
            const location = result.geometry.location;
            return location;
        } else{
            throw new Error('Unable to retrieve latitude and longitude.');
        }
    } catch (error) {
        console.error('Error:', error.message);
        throw error;
    }
}

const EARTH_RADIUS = 6371000;

function toRadians(deg) {
    return (deg * Math.PI) / 180;
}

function toDegrees(rad) {
    return (rad * 180) / Math.PI;
}

function latLngToCartesian(lat, lng, centerLat) {
    const x = EARTH_RADIUS * toRadians(lng) * Math.cos(toRadians(centerLat));
    const y = EARTH_RADIUS * toRadians(lat);
    return { x, y };
}

function cartesianToLatLng(x, y, centerLat) {
    const lat = toDegrees(y / EARTH_RADIUS);
    const lng = toDegrees(x / (EARTH_RADIUS * Math.cos(toRadians(centerLat))));
    return { lat, lng };
}

function rotateCartesianPoint(point, centroid, angle) {
    const rad = (Math.PI / 180) * angle;
    const x = point.x - centroid.x;
    const y = point.y - centroid.y;

    const rotatedX = x * Math.cos(rad) - y * Math.sin(rad);
    const rotatedY = x * Math.sin(rad) + y * Math.cos(rad);

    return {
        x: rotatedX + centroid.x,
        y: rotatedY + centroid.y,
    };
}

function calculateCentroid(bounds) {
    let latSum = 0, lngSum = 0;
    bounds.forEach(point => {
        latSum += point.lat;
        lngSum += point.lng;
    });
    return {
        lat: latSum / bounds.length,
        lng: lngSum / bounds.length
    };
}

export const rotateBounds = async (bounds, angle) => {
    const centroid = calculateCentroid(bounds);

    const centroidCartesian = latLngToCartesian(centroid.lat, centroid.lng, centroid.lat);

    return bounds.map(point => {
        const pointCartesian = latLngToCartesian(point.lat, point.lng, centroid.lat);
        const rotatedCartesian = rotateCartesianPoint(pointCartesian, centroidCartesian, angle);
        return cartesianToLatLng(rotatedCartesian.x, rotatedCartesian.y, centroid.lat);
    });
}