import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableRow,
    Paper,
    TableHead,
    Pagination,
    Grid,
    Typography,
    Tooltip
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import {$crud} from "../factories/CrudFactory";
import { styled } from '@mui/material/styles';
import {debounce} from "lodash";
import moment from "moment-timezone";
import CreateEnquiryPopup from "./Dialog/CreateEnquiryPopup";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        alignContent: "center"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    },
    '& td, & th': {
        padding: "0px 15px"
    },
}));

const BuilderEnquires = () => {
    const [data, setData] = useState<any[]>([]);

    const rowsPerPage = [25, 50, 75, 100];
    const [pagination, setPagination] = useState({
        page: 1,
        limit: rowsPerPage[0]
    });
    const [total, setTotal] = useState({
        pages: 0,
        records: 0
    });

    const [filters, setFilters] = useState({search: ""});
    const [search, setSearch] = useState<string>("");
    const [enquiry, setEnquiry] = useState<any>(null);
    let history = useHistory();

    async function getAllEnquires() {
        try{
            const {data: {total, enquires}} = await $crud.get("builder/enquiries", {
                ...pagination,
                attributes: "locationName customerDetails directionSend addressId createdAt",
                search
            });
            setData(enquires);
            setTotal({
                pages: Math.ceil(total / pagination.limit),
                records: total
            });
        }  catch (e) {
            console.log(e);
        }
    }

    const setFilter = useCallback((key, value) => {
        setFilters(prev => {
            return {
                ...prev,
                [key]: value
            };
        });
    }, []);

    const debounceSetFilter = useCallback(debounce(setFilter, 800), []);

    useEffect(() => {
        let isLoggedIn = sessionStorage.getItem('isLoggedIn');
        getAllEnquires();
    }, [pagination, filters]);

    useEffect(() => {
        debounceSetFilter(search);
    }, [search]);

    const deleteEnquiry = async (itemId) => {
        try{
            const {type} = await $crud.delete(`builder/enquiry/${itemId}`);
            if(type === "success")
                getAllEnquires();
        }  catch (e) {
            console.log(e);
        }
    };

    const showAlert = (item) => {
        Swal.fire({
            title: 'Confirm Deletion',
            html: `
            <p>Are you sure you want to delete this Enquiry with following data ?</p>
            <p style="display: flex; justify-content: space-evenly;">
                <span>Name: <b>${item.customerDetails?.firstName} ${item.customerDetails?.lastName}</b></span>
                <span>Email: <b>${item.customerDetails?.email}</b></span>
                <span>Phone: <b>${item.customerDetails?.mobilePhone}</b></span>
            </p>
            
            <p style="display: flex; justify-content: space-evenly;">
                <span>Map Name: <b>${item.address?.name}</b></span>
                <span>Location Name: <b>${item.locationName}</b></span>
            </p>
            `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                const confirmButtonText = Swal.getConfirmButton()!.textContent;
                deleteEnquiry(item?._id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    };

    const getEnquiryById = async (enquiryId) => {
        try{
            const {type, data} = await $crud.get(`builder/enquiry/${enquiryId}`);

            if(type === "success"){
                setEnquiry(data.enquiry);
            }
        }catch (e) {
            console.log(e);
        }
    }

    return (
        <div style={{ display: 'flex'}}>

            {
                enquiry && <CreateEnquiryPopup
                    mapId={enquiry?.addressId}
                    location={enquiry?.location}
                    customerDetails={enquiry?.customerDetails}
                    enquiryId={enquiry?._id}
                    onClose={() => {
                        setEnquiry(null);
                        getAllEnquires();
                    }}
                />
            }

            <div
                style={{
                    flex: 1,
                    margin: '0 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div className={"map-bg-img"}></div>
                <div
                    style={{
                        // display: 'flex',
                        // justifyContent: 'space-between',
                        // alignItems: 'center',
                        width: '100%',
                        margin: '20px 0',
                    }}
                >
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <h5 className={"logo title gradient-text"} style={{ marginRight: 'auto', fontWeight: "bold" }}>Enquires</h5>
                        </Grid>
                    </Grid>
                </div>
                <TableContainer component={Paper} className={"table"}>
                    <Table sx={{ minWidth: 700 }} size={"small"} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '' }}>
                                    First Name
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '' }}>
                                    Last Name
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '' }}>
                                    Email
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '' }}>
                                    Phone#
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '' }}>
                                    Zip Code
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '' }}>
                                    Map Name
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '' }}>
                                    Plot Name
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '' }}>
                                    Location Sent By
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '' }}>
                                    Visit Date and time
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '5%' }}>
                                    Actions
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item, index) => (
                                <StyledTableRow  key={item._id}>
                                    <StyledTableCell>{item.customerDetails.firstName || ""}</StyledTableCell>
                                    <StyledTableCell>{item.customerDetails.lastName || ""}</StyledTableCell>
                                    <StyledTableCell>{item.customerDetails.email || ""}</StyledTableCell>
                                    <StyledTableCell>{item.customerDetails.mobilePhone || ""}</StyledTableCell>
                                    <StyledTableCell>{item.customerDetails.zip || ""}</StyledTableCell>
                                    <StyledTableCell>{item.address.area || ""}</StyledTableCell>
                                    <StyledTableCell>{item.locationName || ""}</StyledTableCell>
                                    <StyledTableCell>{item.directionSend || ""}</StyledTableCell>
                                    <StyledTableCell>{moment(item.createdAt).tz("UTC").format("MMM-DD-YYYY HH:mm:ss")}</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>
                                        <Tooltip title="Edit">
                                            <IconButton
                                                onClick={() => {
                                                    getEnquiryById(item?._id);
                                                }} aria-label="edit" style={{ padding: '6px', color: "orange" }}>
                                                <EditIcon fontSize="medium" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton
                                                onClick={() => showAlert(item)} aria-label="delete" style={{ padding: '6px', color: "#f00" }}>
                                                <DeleteIcon fontSize="medium" />
                                            </IconButton>
                                        </Tooltip>
                                    </StyledTableCell>
                                </StyledTableRow >
                            ))}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <StyledTableCell style={{padding: "7px"}} colSpan={10}>
                                    <Grid container alignItems={"center"} justifyContent={"right"}>
                                        <Grid item>
                                            <Typography className={"font-weight-bold"} variant={"body1"}>Total : {total.records} | Page:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Pagination
                                                count={total.pages}
                                                color="primary"
                                                onChange={(e, value) => {
                                                    setPagination({...pagination, page: value})
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container justifyContent={"center"} alignItems={"center"}>
                                                <Grid item>
                                                    <Typography className={"font-weight-bold mr-2"} variant={"body2"}>Show</Typography>
                                                </Grid>

                                                <Grid item>
                                                    <select
                                                        style={{fontSize: "16px"}}
                                                        value={String(pagination.limit)} placeholder="" name="limit"
                                                        onChange={
                                                            e => {
                                                                // this.props.onLimitChange(Number(e.target.value));
                                                                // this.changePage(1);
                                                                setPagination({...pagination, limit: Number(e.target.value)})
                                                            }
                                                        }
                                                    >
                                                        {
                                                            rowsPerPage.map((val, key) => <option
                                                                key={key}
                                                                value={val}
                                                            >
                                                                {val}
                                                            </option>)
                                                        }
                                                    </select>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledTableCell>
                            </StyledTableRow >
                        </TableFooter>
                    </Table>
                </TableContainer>

            </div>
        </div>
    );
};

export default BuilderEnquires;
