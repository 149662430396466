import React, {useState} from "react";
import {Grid, Typography, Button, TextField, IconButton, Tooltip} from "@mui/material";
import {X} from 'react-feather';
import {$crud} from "../../factories/CrudFactory";
import Swal from "sweetalert2";
import {getCenterByLocation} from "../../helpers";
import InfoIcon from '@mui/icons-material/Info';
import PlotDetailsDialog from './PlotDetailsDialog';

export default function CreateEnquiryPopup ({mapId, location, onClose, customerDetails = null, enquiryId = null}) {
    const [params, setParams] = useState<any>(customerDetails ?? {});
    const [loading, setLoading] = useState<any>(false);
    const [openPlotDetailsDialog, setOpenPlotDetailsDialog] = useState<any>(false);

    const createEnquiry = async (directionSend) => {
        const warnings: string[] = [];

        if (!params.firstName || params.firstName?.trim() === "")
            warnings.push('Please enter First name.');

        if (!params.mobilePhone || params.mobilePhone?.trim() === "")
            warnings.push('Please enter Mobile phone.');

        if (!params.email || params.email?.trim() === "")
            warnings.push('Please enter Email.');


        if(warnings.length){
            await Swal.fire({
                icon: "warning",
                text: warnings[0]
            });
            return;
        }

        try{
            const locationCenter = await getCenterByLocation(location);
            const {type, data} = await $crud.post("builder/create-enquiry", {
                addressId: mapId,
                locationId: location._id,
                locationName: location.Name,
                customerDetails: params,
                lat: locationCenter.lat,
                lng: locationCenter.lng,
                directionSend
            });

            if(type === "success"){
                setParams({});
                onClose();
            }
        }catch (e) {
            console.log(e);
        }
    };

    const updateEnquiry = async () => {
        const warnings: string[] = [];

        if (!params.firstName || params.firstName?.trim() === "")
            warnings.push('Please enter First name.');

        if (!params.mobilePhone || params.mobilePhone?.trim() === "")
            warnings.push('Please enter Mobile phone.');

        if (!params.email || params.email?.trim() === "")
            warnings.push('Please enter Email.');


        if(warnings.length){
            await Swal.fire({
                icon: "warning",
                text: warnings[0]
            });
            return;
        }

        try{
            const {type, data} = await $crud.post("builder/update-enquiry", {
                enquiryId,
                customerDetails: params
            });

            if(type === "success"){
                // setParams({});
                onClose();
            }
        }catch (e) {
            console.log(e);
        }
    };

    return location && <div className={`popup-wrapper ${location?._id && "show"}`}>
        <PlotDetailsDialog
            open={openPlotDetailsDialog}
            onClose={() => setOpenPlotDetailsDialog(false)}
            locationData={location}
        />

        <div className={"content"} style={{maxWidth: "420px"}}>
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container alignItems={"center"} justifyContent={"space-between"}>
                            <Grid>
                                <Typography variant={"h6"}>Customer Details</Typography>
                            </Grid>

                            <Grid>
                                <IconButton
                                    onClick={onClose}
                                >
                                    <X />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <Grid container alignItems={"center"}>
                <Grid item xs={12} className={"my-3"}>
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <Typography variant={"h6"}>Selected House #: {location.Name}</Typography>
                        </Grid>

                        <Grid item>
                            <Tooltip title="Plot Details">
                                <IconButton
                                    onClick={() => setOpenPlotDetailsDialog(true)}
                                    style={{color: "#2d7fe6"}}>
                                    <InfoIcon fontSize="large"/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        className='mb-4'
                        label={"First Name"}
                        type="text"
                        size="small"
                        value={params.firstName ?? ""}
                        onChange={(e) => setParams({
                            ...params,
                            firstName: e.target.value
                        })}
                        required
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        className='mb-4'
                        label={"Last Name"}
                        type="text"
                        size="small"
                        value={params.lastName ?? ""}
                        onChange={(e) => setParams({
                            ...params,
                            lastName: e.target.value
                        })}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        className='mb-4'
                        label={"Zip Code"}
                        type="text"
                        size="small"
                        value={params.zip ?? ""}
                        onChange={(e) => setParams({
                            ...params,
                            zip: e.target.value
                        })}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        className='mb-4'
                        label={"Mobile Phone"}
                        type="text"
                        size="small"
                        value={params.mobilePhone ?? ""}
                        onChange={(e) => setParams({
                            ...params,
                            mobilePhone: e.target.value
                        })}
                        required
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        className='mb-4'
                        label={"Email"}
                        type="text"
                        size="small"
                        value={params.email ?? ""}
                        onChange={(e) => setParams({
                            ...params,
                            email: e.target.value
                        })}
                        required
                        fullWidth
                    />
                </Grid>

            </Grid>

            <div className={"buttons"} style={{marginTop: "0px"}}>
                {
                    !customerDetails && <Grid container justifyContent={"center"} alignItems={"center"}>
                        <Grid item>
                            <Button
                                size={"small"}
                                disabled={loading}
                                className="button mt-3"
                                onClick={() => createEnquiry("WHATSAPP")}
                            >
                                Send Direction on whatsapp
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                size={"small"}
                                disabled={loading}
                                className="button mt-3 ml-3"
                                onClick={() => createEnquiry("SMS")}
                            >
                                Send Direction as SMS
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                size={"small"}
                                disabled={loading}
                                className="button mt-3"
                                onClick={() => createEnquiry("EMAIL")}
                            >
                                Send Direction as an Email
                            </Button>
                        </Grid>
                    </Grid>
                }

                {
                    customerDetails && <Grid container justifyContent={"end"} alignItems={"center"}>
                        <Grid item>
                            <Button
                                size={"small"}
                                disabled={loading}
                                className="button mt-3"
                                onClick={() => updateEnquiry()}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                }
            </div>
        </div>
    </div>;
}
